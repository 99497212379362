import { SideBarProducts } from '../SideBarProducts/SideBarProducts'
import { ContainerProducts } from '../ContainerProducts/ContainerProducts'

export const MainProducts = () => {

  return (
    <main className="mainProducts">

        <SideBarProducts />

        <ContainerProducts />

           
    </main>
  )
}
