import React from 'react'

export const ContainerHeadingResetPass = () => {
  return (
    <section className="heading">
        <h1 className='tittleForgotPass'>Reestablecer Contraseña</h1>
        <p className='textForgotPass'>Introduce tu contraseña para reestablecerla</p>
    </section>
  )
}
