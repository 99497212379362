import React from 'react'

export const ContainerHeadingForgotPass = () => {
  return (
    <section className="heading">           
        <h1 className='tittleForgotPass'>Recuperar Contraseña</h1>
        <p className='textForgotPass'>Introduce tu correo electronico para hacer el envio del enlace de recuperacion</p>
    </section>
  )
}
